import React from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Auth from 'modules/Auth';
import TrainerDashboard from 'modules/TrainerDashboard';
import TrainerSession from './modules/TrainerSession';
import TraineeSession from 'modules/TraineeSession';
import TraineeDashboard from 'modules/TraineeDashboard';

import { ADMIN, TRAINEE, TRAINER } from 'stateConstants';
import { useAuthState } from './contexts';
import AdminDashboard from './modules/AdminDashboard';
import { Loading } from 'components';
import settings from 'settings';

const Router: React.FC = () => {
  const { isLoggedIn, userType, isLoading, initialLoading } = useAuthState();

  if (isLoading || initialLoading) return <Loading />;

  if (!isLoggedIn) {
    if (settings.mfa_redirect) {
      // LEGACY window.location.href = 'https://auth.traderion.com/ma';
      window.location.href = 'https://portal.traderion.com/ma';
      return null;
    }
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/auth" component={Auth} />
          <Redirect to="/auth" />
        </Switch>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/dashboard">
          {userType === TRAINER && <TrainerDashboard />}
          {userType === TRAINEE && <TraineeDashboard />}
          {userType === ADMIN && <AdminDashboard />}
        </Route>
        <Route path="/sessions/:sessionId">
          {userType === TRAINER && <TrainerSession />}
          {userType === TRAINEE && <TraineeSession />}
        </Route>
        <Redirect to="/dashboard" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
