const settings = {
  BASE_URL: '',
  API_URL: '',
  SOCKET_URL: '',
  mfa_redirect: true,
};

switch (process.env.REACT_APP_GOAL) {
  case 'production': {
    settings.BASE_URL = `https://api-prod-ma.traderion.com`;
    settings.API_URL = `https://api-prod-ma.traderion.com/api`;
    settings.SOCKET_URL = `wss://api-prod-ma.traderion.com/api`;
    break;
  }
  case 'stage': {
    settings.BASE_URL = `https://api-stage-ma.traderion.com`;
    settings.API_URL = `https://api-stage-ma.traderion.com/api`;
    settings.SOCKET_URL = `wss://api-stage-ma.traderion.com/api`;
    break;
  }
  default: {
    settings.BASE_URL = 'http://0.0.0.0:8001';
    settings.API_URL = `http://0.0.0.0:8001/api`;
    settings.SOCKET_URL = `ws://0.0.0.0:8001/api`;
  }
}

export default settings;
