import React, { createContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { useQueryClient } from 'react-query';
import { useLogin, useMe } from './actions';
import { Person } from 'types';
import { useSessionId } from '../../hooks';
import axios from 'axios';
import settings from '../../settings';
import AuthHeader from '../../services/AuthHeader';

type State = {
  userId: number;
  userType: number;
  username: string;
  token?: string;
  isLoggedIn: boolean;
  isLoading: boolean;
  loginError: string | undefined;
  error: unknown;
  person: Person;
  initialLoading: boolean;
  showPersonModal: boolean;
};
export const AuthStateContext = createContext<State | undefined>(undefined);

type Actions = {
  login: (username: string, password: string) => void;
  logout: () => void;
  setPerson: (person?: Person) => void;
  resetLoginError: () => void;
  setShowPersonModal: (showPersonModal: boolean) => void;
  setPersonAndPersist: (_person: Person | undefined) => void;
};
export const AuthActionsContext = createContext<Actions | undefined>(undefined);

const AuthProvider: React.FC = ({ children }) => {
  const [initialLoading, setInitialLoading] = useState(true);

  const token = Cookies.get('maToken');
  const queryClient = useQueryClient();

  const { data: queryData, isLoading, error } = useMe(token || '');
  const savedPerson = localStorage.getItem('person');
  const [person, setPerson] = useState<Person | undefined>(
    savedPerson ? JSON.parse(savedPerson) : undefined
  );
  const [showPersonModal, setShowPersonModal] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string | undefined>(undefined);

  const setPersonAndPersist = (_person: Person | undefined) => {
    if (_person) {
      // Save the person object to localStorage
      localStorage.setItem('person', JSON.stringify(_person));
    } else {
      // Remove the person object from localStorage if logging out or if person is undefined
      localStorage.removeItem('person');
    }
    setPerson(_person);
  };

  useEffect(() => {
    if (!isLoading) {
      setInitialLoading(false); // Set to false once loading completes
    }
  }, [isLoading]);
  const login = useLogin({
    onError: (_error: any) => {
      if (_error.response?.data?.non_field_errors) {
        setLoginError(_error.response.data.non_field_errors[0]);
      }
    },
  });

  const state: State = {
    userId: queryData?.id || 0,
    userType: queryData?.user_type ?? -1,
    username: queryData?.username || '',
    token: token || '',
    isLoggedIn: !!token,
    isLoading,
    loginError,
    error,
    initialLoading,
    person: person as Person,
    showPersonModal,
  };

  const actions: Actions = {
    login: (username, password) => login.mutate({ username, password }),
    logout: () => {
      if (person) {
        axios.post(
          `${settings.API_URL}/users/person_logged_out/`,
          { person_id: person.id },
          { headers: AuthHeader() }
        );
      }

      Cookies.remove('maToken', { path: '/', domain: '.traderion.com' });
      queryClient.invalidateQueries().then(
        () => {
          queryClient.removeQueries();
        },
        () => {
          queryClient.removeQueries();
        }
      );
      setPerson(undefined);
    },
    setShowPersonModal,
    setPerson,
    setPersonAndPersist,
    resetLoginError: () => setLoginError(undefined),
  };

  return (
    <AuthStateContext.Provider value={state}>
      <AuthActionsContext.Provider value={actions}>
        {children}
      </AuthActionsContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthState = () => {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
};

const useAuthActions = () => {
  const context = React.useContext(AuthActionsContext);
  if (context === undefined) {
    throw new Error('useAuthActions must be used within a AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuthState, useAuthActions };
