import { useMutation, useQuery, useQueryClient } from 'react-query';
import settings from 'settings';
import axios from 'axios';
import Cookies from 'js-cookie';

type MeUser = {
  id: number;
  user_type: number;
  username: string;
};

export const useMe = (token?: string) => {
  return useQuery<MeUser>(
    'users/me',
    async () => {
      const url = `${settings.API_URL}/users/me`;

      const { data } = await axios.get(url, {
        headers: {
          authorization: 'JWT ' + token,
        },
      });
      return data;
    },
    {
      retry: 0,
      onError: (err) => {
        console.log("auth", err);
        Cookies.remove('maToken', { path: '/', domain: '.traderion.com' });
      },
      enabled: !!token,
    }
  );
};

const loginAction = async (params: { username: string; password: string }) => {
  const url = `${settings.API_URL}/auth/get-token/`;

  const { data } = await axios.post(url, params);
  return data;
};

export const useLogin = (options?: { onError?: (error: any) => void }) => {
  const queryClient = useQueryClient();

  return useMutation(loginAction, {
    onSettled: async (data) => {
      if (!data) return;

      Cookies.set('maToken', data.token);
      queryClient.invalidateQueries('users/me');
    },
    onError: options?.onError
  });
};
